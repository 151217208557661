/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, DatePicker, Form, Input, InputNumber, message } from 'antd';
import axios from 'axios';

import Utils from '../../../../Assets/Scripts/Utils';
import UploadFileField from '../UploadFileField';

function FormLogisticFieldsComponent({
  fieldsPermissions,
  handleTaskUpdate,
  form,
  task,
  setUploadFileType,
  setUploadFileModalOpen,
  isTaskReadOnly,
  isEditing,
}) {
  const getAdressByCEP = async (cep) => {
    const cleanCep = cep.replace(/[-_]/g, '');
    if (cleanCep?.length === 8) {
      const address = await axios
        .get(`https://viacep.com.br/ws/${cleanCep}/json`)
        .then((res) => res.data)
        .catch((error) => {
          Utils.logError(error);
          message.error('Erro ao buscar CEP!');
          return null;
        });

      if (address && !address.erro) {
        const data = {};
        data.streetLogistic = address.logradouro;
        data.districtLogistic = address.bairro;
        data.cityLogistic = address.localidade;
        data.stateLogistic = address.uf;
        data.complementLogistic = address.complemento;

        form.setFieldsValue({ ...form.getFieldsValue(), ...data });
      } else {
        message.error('Oops. CEP não encontrado!');
      }
    }
  };

  return (
    <Col span={24}>
      <Card title="Logística" bordered={false} className="task-form-section-card">
        {/* Quando Avançado 24 horas Hibrido (Endereço Teoria) */}
        {fieldsPermissions?.Advance24h.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="advance24h"
              label="Quando Avançado 24 horas Hibrido (Endereço Teoria)"
              rules={[
                {
                  required: fieldsPermissions?.Advance24h.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.Advance24h.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Endereço de Parada Transporte */}
        {fieldsPermissions?.TransportStopAddress.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="transportStopAddress"
              label="Endereço de Parada Transporte"
              rules={[
                {
                  required: fieldsPermissions?.TransportStopAddress.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.TransportStopAddress.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Ponto de Referência */}
        {fieldsPermissions?.ReferencePoint.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="referencePoint"
              label="Ponto de Referência"
              rules={[
                {
                  required: fieldsPermissions?.ReferencePoint.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.ReferencePoint.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Nome Responsável */}
        {fieldsPermissions?.ResponsiblePersonNameLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="responsiblePersonNameLogistic"
              label="Nome Responsável"
              rules={[
                {
                  required: fieldsPermissions?.ResponsiblePersonNameLogistic.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.ResponsiblePersonNameLogistic.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Telefone Responsável */}
        {fieldsPermissions?.ResponsiblePersonPhoneLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="responsiblePersonPhoneLogistic"
              label="Telefone Responsável"
              rules={[
                {
                  required: fieldsPermissions?.ResponsiblePersonPhoneLogistic.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.ResponsiblePersonPhoneLogistic.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* E-mail Responsável */}
        {fieldsPermissions?.ResponsiblePersonEmailLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="responsiblePersonEmailLogistic"
              label="E-mail Responsável"
              rules={[
                {
                  validator: async (_, value) => {
                    // Retorna uma promessa resolvida se não é obrigatório e não há valor
                    if (!value && !fieldsPermissions?.ResponsiblePersonEmailLogistic.isRequired) {
                      return Promise.resolve();
                    }

                    // Verifica a condição de campo obrigatório
                    if (fieldsPermissions?.ResponsiblePersonEmailLogistic.isRequired && !value) {
                      return Promise.reject(new Error('Campo obrigatório'));
                    }

                    // Valida o formato de vários e-mails separados por ponto e vírgula
                    const emails = value.split(';').map((email) => email.trim());
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const isValid = emails.every((email) => emailRegex.test(email));
                    if (!isValid) {
                      return Promise.reject(
                        new Error(
                          'Por favor, insira um ou mais e-mails válidos separados por ponto e vírgula (;)'
                        )
                      );
                    }

                    // Garante que nenhum caractere inválido seja usado para separar e-mails
                    if (/[,:/]/.test(value)) {
                      return Promise.reject(
                        new Error('Utilize apenas ponto e vírgula (;) para separar os e-mails.')
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.ResponsiblePersonEmailLogistic.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* CEP */}
        {fieldsPermissions?.PostalCodeLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="postalCodeLogistic"
              label="CEP"
              rules={[
                {
                  required: fieldsPermissions?.PostalCodeLogistic?.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                id="postalCodeLogistic"
                maxLength={9}
                placeholder="o campo está vazio"
                onChange={(e) => getAdressByCEP(e.target.value)}
                disabled={
                  fieldsPermissions?.PostalCodeLogistic.access !== 2 || isTaskReadOnly || !isEditing
                }
              />
            </Form.Item>
          </Col>
        )}

        {/* Rua */}
        {fieldsPermissions?.StreetLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="streetLogistic"
              label="Rua"
              rules={[
                {
                  required: fieldsPermissions?.StreetLogistic.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.StreetLogistic.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Número */}
        {fieldsPermissions?.NumberLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="numberLogistic"
              label="Número"
              rules={[
                {
                  required: fieldsPermissions?.NumberLogistic.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.NumberLogistic.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Bairro */}
        {fieldsPermissions?.DistrictLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="districtLogistic"
              label="Bairro"
              rules={[
                {
                  required: fieldsPermissions?.DistrictLogistic.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.DistrictLogistic.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Cidade */}
        {fieldsPermissions?.CityLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="cityLogistic"
              label="Cidade"
              rules={[
                {
                  required: fieldsPermissions?.CityLogistic.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.CityLogistic.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Estado */}
        {fieldsPermissions?.StateLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="stateLogistic"
              label="Estado"
              rules={[
                {
                  required: fieldsPermissions?.StateLogistic.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.StateLogistic.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Complemento */}
        {fieldsPermissions?.ComplementLogistic.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="complementLogistic"
              label="Complemento"
              rules={[
                {
                  required: fieldsPermissions?.ComplementLogistic.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.ComplementLogistic.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Reembolso */}
        {fieldsPermissions?.Refund.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="refund"
              label="Reembolso"
              rules={[
                {
                  required: fieldsPermissions?.Refund.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <InputNumber
                min={0}
                step={0.0001}
                formatter={Utils.priceFormatter}
                parser={Utils.priceParser}
                style={{ width: '100%' }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Valor Transporte */}
        {fieldsPermissions?.TransportValue.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="transportValue"
              label="Valor Transporte"
              rules={[
                {
                  required: fieldsPermissions?.TransportValue.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <InputNumber
                min={0}
                step={0.0001}
                formatter={Utils.priceFormatter}
                parser={Utils.priceParser}
                style={{ width: '100%' }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Valor Pago Transporte */}
        {fieldsPermissions?.AmountPaidTransportation.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="amountPaidTransportation"
              label="Valor Pago Transporte"
              rules={[
                {
                  required: fieldsPermissions?.AmountPaidTransportation.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <InputNumber
                min={0}
                step={0.0001}
                formatter={Utils.priceFormatter}
                parser={Utils.priceParser}
                style={{ width: '100%' }}
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Data de Confirmação */}
        {fieldsPermissions?.ConfirmationDate.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="confirmationDate"
              label="Data de Confirmação"
              rules={[
                {
                  required: fieldsPermissions?.ConfirmationDate.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{
                  borderRadius: 16,
                }}
                disabled={
                  fieldsPermissions?.ConfirmationDate.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Confirmado por */}
        {fieldsPermissions?.ConfirmedBy.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="confirmedBy"
              label="Confirmado por"
              rules={[
                {
                  required: fieldsPermissions?.ConfirmedBy.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.ConfirmedBy.access !== 2 || isTaskReadOnly || !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Nome Responsável Confirmação */}
        {fieldsPermissions?.NameResponsibleConfirmation.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="nameResponsibleConfirmation"
              label="Nome Responsável Confirmação"
              rules={[
                {
                  required: fieldsPermissions?.NameResponsibleConfirmation.isRequired,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.NameResponsibleConfirmation.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* E-mail Responsável Confirmação */}
        {fieldsPermissions?.EmailResponsibleConfirmation.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="emailResponsibleConfirmation"
              label="E-mail Responsável Confirmação"
              rules={[
                {
                  required: fieldsPermissions?.EmailResponsibleConfirmation.isRequired,
                  message:
                    'Por favor, insira um ou mais e-mails válidos separados por ponto e vírgula (;)',
                },
                () => ({
                  validator(_, value) {
                    if (!value) return Promise.resolve();
                    const emails = value.split(';').map((email) => email.trim());
                    if (emails.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Por favor, insira um ou mais e-mails válidos.')
                    );
                  },
                }),
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.EmailResponsibleConfirmation.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* Telefone Responsável Confirmação */}
        {fieldsPermissions?.PhoneResponsibleConfirmation.access !== 0 && (
          <Col span={24}>
            <Form.Item
              name="phoneResponsibleConfirmation"
              label="Telefone Responsável Confirmação"
              rules={[
                {
                  required: fieldsPermissions?.PhoneResponsibleConfirmation.isRequired,
                  message: 'Telefone do responsável pela Confirmação',
                },
              ]}
            >
              <Input
                disabled={
                  fieldsPermissions?.PhoneResponsibleConfirmation.access !== 2 ||
                  isTaskReadOnly ||
                  !isEditing
                }
                placeholder="o campo está vazio"
              />
            </Form.Item>
          </Col>
        )}

        {/* NF Transporte - Upload */}
        {fieldsPermissions?.NFTransportation.access !== 0 && (
          <Col span={24}>
            <Form.Item label="NF Transporte" name="nfTransportation">
              <UploadFileField
                handleTaskUpdate={handleTaskUpdate}
                form={form}
                task={task}
                fieldName="nfTransportation"
                fieldPermission={fieldsPermissions?.NFTransportation}
                isTaskReadOnly={isTaskReadOnly}
                isEditing={isEditing}
                setUploadFileType={setUploadFileType}
                setUploadFileModalOpen={setUploadFileModalOpen}
              />
            </Form.Item>
          </Col>
        )}
      </Card>
    </Col>
  );
}

export default FormLogisticFieldsComponent;
