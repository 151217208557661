/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-return */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-unsafe-optional-chaining */
import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Col, Form, message, Modal, Row, Spin } from 'antd';
import { Scheduler } from 'devextreme-react';
import { AppointmentDragging, Resource } from 'devextreme-react/scheduler';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import QuoteList from '../../Components/QuoteList/QuoteList';
import ReasonModal from '../../Components/ReasonModal/ReasonModal';
import Appointment from '../../Components/Schedule/Appointment';
import AppointmentTooltipComponent from '../../Components/Schedule/AppointmentTooltip';
import DataCell from '../../Components/Schedule/DataCell';
import DateCell from '../../Components/Schedule/DateCell';
import InstructorBookingPopup from '../../Components/Schedule/InstructorBooking/InstructorBookingPopup';
import ModalConfirmBody from '../../Components/Schedule/ModalConfirmBody';
import NewSlotPopup from '../../Components/Schedule/NewSlotPopup';
import OverviewPopup from '../../Components/Schedule/OverviewPopup';
import ResourceCellRender from '../../Components/Schedule/ResourceCellRender';
import ResumePopup from '../../Components/Schedule/ResumePopup';
import ScheduleUtils from '../../Components/Schedule/ScheduleUtils';
import TaskCommentFunctions from '../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../Services/axiosService';
import UserFunctions from '../User/Register/UserFunctions';

import {
  fetchProduct,
  fetchSchedulingScheduleFilter,
  fetchTasks,
} from './API/ScheduleSchedulingAPI';
import SchedulingFunctions from './API/SchedulingFunctions';
import TaskDrawer from './Components/TaskDrawer';

import './ScheduleScheduling.scss';

let scheduleDate = moment(new Date()).format('YYYY-MM-DD');
let currentCTName = '';
let taskAcceptanceDeadline = 0;

let currentOpenTask = null;
const confirmationData = null;
let event = '';
let onModalOk = null;
let firstOpen = true;
// let trainingOpened = false;

const views = [
  {
    name: 'Dia',
    type: 'day',
    cellDuration: 60,
  },
];

const notifyDisabledDate = () => {
  message.warning('Não é possível criar ou mover um treinamento para uma data desabilitada.');
};

const notifyDisableDelete = () => {
  message.warning('Não é possível remover um agendamento neste estágio!');
};

const notifySameInstructors = () => {
  message.warning('O "Instrutor" e o "Instrutor Auxiliar" não podem ser o mesmo!');
};

const notifyRetroactiveDay = () => {
  message.warning('Não é possível agendar treinamentos em dias retroativos!');
};

const notifyExceededCapacity = (students, capacity) => {
  message.warning(`Capacidade de alunos por dia excedida (${students}/${capacity})!`);
};

function ScheduleSchedulingComponent() {
  // eslint-disable-next-line radix
  const dealId = window.location.search.split('=')[1];
  const draggingGroupName = 'appointmentsGroup';
  const groups = ['resourceId'];

  const schedulerRef = useRef(null);
  const newSlotPopupRef = useRef(null);
  const overviewPopupRef = useRef(null);
  const resumePopupRef = useRef(null);
  const instructorBookingPopupRef = useRef(null);
  const quoteListRef = useRef();

  const urlParams = useParams();
  const location = useLocation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [scheduleParams, setScheduleParams] = useState();
  const [appointments, setAppointments] = useState([]);
  const [trainingCenter, setTrainingCenter] = useState();
  const [quoteList, setQuoteList] = useState();
  const [instructorOptions, setInstructorOptions] = useState();
  const [driverOptions, setDriverOptions] = useState();
  const [transportOptions, setTransportOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [trainingsOptions, setTrainingsOptions] = useState();
  const [blockedDates, setBlockedDates] = useState([]);
  const [instructorsBlockedDates, setInstructorsBlockedDates] = useState([]);
  const [slots, setSlots] = useState([]);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [stages, setStages] = useState();
  const [ctResources, setCtResources] = useState();
  const [ctResourcesSlots, setCtResourcesSlots] = useState();
  const [isReasonModalOpen, setReasonModalOpen] = useState(false);
  const [reasonsOptions, setReasonsOptions] = useState();
  const [usedCapacity, setUsedCapacity] = useState(0);
  const [bitrixProducts, setBitrixProducts] = useState([]);
  const [overview, setOverview] = useState([]);
  const [resume, setResume] = useState([]);
  const [bitrixUsers, setBitrixUsers] = useState([]);
  const [openTraining, setOpenTraining] = useState();
  const [reasonNoBillingOptions, setReasonNoBillingOptions] = useState([]);
  const [trainingCenterOptions, setTrainingCenterOptions] = useState();
  const [taskRescheduling, setTaskRescheduling] = useState(null);
  const [isTaskReadOnly, setIsTaskReadOnly] = useState(false);

  // Certificado
  const [certificateModelOptions, setCertificateModelOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [isTaskDrawerVisible, setIsTaskDrawerVisible] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);

  // Filtros
  const [schedulingScheduleFilter, setSchedulingScheduleFilter] = useState();

  let schedulerDefaultDate = null;
  if (schedulingScheduleFilter?.filters?.currentDate) {
    const [year, month, day] = schedulingScheduleFilter.filters.currentDate.split('-');
    schedulerDefaultDate = new Date(Number(year), Number(month) - 1, Number(day));
  }

  const getCurrentDate = () => scheduleDate;

  const updateOverviewAndResume = () => {
    const currentStartDate = schedulerRef.current.instance.getStartViewDate();
    const currentEndDate = schedulerRef.current.instance.getStartViewDate();
    const startDate = moment(currentStartDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
    const endDate = moment(currentEndDate.setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');
    SchedulingFunctions.getOverviewData(appointments, setOverview, startDate, endDate);
    SchedulingFunctions.getResumeData(appointments, setResume, startDate, endDate);
  };

  const schedulingType = async (e, oldTaskData, startDate) => {
    try {
      const data = e;
      const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));

      if (data.acceptTerm2 && data.acceptTerm2 !== '') {
        data.stage = stages.find(({ id }) => id === 12); // 12 = Estágio "Reservado"
      } else {
        data.stage = stages.find(({ id }) => id === 10); // 10 = Estágio "Pré-Reservado"
      }

      data.trainingCenter = trainingCenter?.commercialName ?? scheduleData.trainingCenter;
      data.trainingCenterId = trainingCenter?.id ?? scheduleData.trainingCenterId;

      setLoadingSchedule(true);
      await api.put('/Task/UpdateTaskScheduling', data).then(async () => {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 3000); // 3 Segundos
        });

        appointments.push(data);
        setAppointments([...appointments.map((appointment, index) => ({ ...appointment, index }))]);
        message.success('Treinamento Agendado!');
      });

      await TaskCommentFunctions.addTaskCommentActivities(data, oldTaskData, 'Scheduling');
      await TaskCommentFunctions.addTaskCommentHistory(data, oldTaskData, 'Scheduling');

      // Envia Notificação ao CT
      await ScheduleUtils.sendPushNotification(data, 'TaskReceived', 'CT', data.trainingCenterId);

      // Atualiza Overview e Resumo
      updateOverviewAndResume();

      // Atualiza as informações em tela
      const quote = quoteList?.find(({ id }) => id === data.dealId);
      const task = quote?.tasks?.find(({ id }) => id === data.id);

      if (quote && task) {
        const indexTask = quote.tasks.indexOf(task);
        const indexQuote = quoteList.indexOf(quote);

        task.stage = data.stage;
        task.startDateFormatted = moment(startDate).format('DD/MM/YYYY');
        quote.tasks.splice(indexTask, 1, task);
        quoteList.splice(indexQuote, 1, quote);
        setQuoteList([...quoteList]);
      }
      setTaskRescheduling(null);
      setLoadingSchedule(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao reservar o treinamento!');
    }
  };

  const onAppointmentAdd = async (e) => {
    try {
      const data = e.itemData;
      const oldTaskData = JSON.parse(JSON.stringify(data)); // Deep copy da task

      // verifica se a data é retroativa
      if (data.startDate < new Date().setHours(0, 0, 0, 0)) {
        e.cancel = true;
        notifyRetroactiveDay();
        return;
      }

      // Realiza algumas validações e formatações
      const shouldCancel = SchedulingFunctions.formatAppointmmentInfo(
        data,
        ctResources,
        taskAcceptanceDeadline,
        ctResourcesSlots
      );
      if (shouldCancel) {
        return;
      }

      // Retorna os treinamentos que já estão nos slots
      const foundAppointments = appointments.filter(
        ({ startDate, endDate, resourceId }) =>
          ((new Date(data.startDate) >= new Date(startDate) &&
            new Date(data.startDate) <= new Date(endDate)) ||
            (new Date(data.endDate) >= new Date(startDate) &&
              new Date(data.endDate) <= new Date(endDate))) &&
          data.resourceId === resourceId
      );

      SchedulingFunctions.addSlotClassId(data, foundAppointments);

      const studentsScheduled = usedCapacity + data.qtyStudentsPerClass;
      const exceedsCapacity = studentsScheduled > trainingCenter.maxCapacityPerDay;

      // Excede Capacidade de Alunos por dia
      if (exceedsCapacity) {
        e.cancel = true;
        notifyExceededCapacity(studentsScheduled, trainingCenter.maxCapacityPerDay);
        return;
      }

      // Regras de Slot ocupado
      if (foundAppointments?.length > 0) {
        const [firstAppointment] = foundAppointments;
        const customProductList = ScheduleUtils.getCustomProductList();
        const isCustomProduct =
          customProductList.includes(data.product?.id) || data.group?.id === 24; // 24 = Personalizado

        if (!data.sharedTraining && !isCustomProduct) {
          e.cancel = true;
          message.warning('Este Treinamento não é compartilhado!');
          return;
        }

        if (
          !firstAppointment.sharedTraining &&
          !firstAppointment.acceptSharedTraining &&
          !isCustomProduct
        ) {
          e.cancel = true;
          message.warning('Este Slot não é compartilhado!');
          return;
        }

        if (firstAppointment.group?.id !== data.group?.id && !isCustomProduct) {
          e.cancel = true;
          message.warning('Este Slot possui um Grupo de Produto diferente!');
          return;
        }
      }

      // Data desabilitada
      const startDate = new Date(data.startDate);
      if (
        !ScheduleUtils.isValidAppointmentDate(startDate, trainingCenter?.workDays) ||
        ScheduleUtils.isBlockedDay(startDate, blockedDates)
      ) {
        e.cancel = true;
        notifyDisabledDate();
        return;
      }

      Modal.confirm({
        title: 'Deseja realmente reservar essa data?',
        content: <ModalConfirmBody itemData={data} />,
        okText: 'Não',
        cancelText: 'Sim',
        centered: true,
        style: { alignItems: 'center' },
        icon: null,
        cancelButtonProps: {
          type: 'primary',
          style: {
            backgroundColor: '#5cb85c',
            borderColor: 'transparent',
            color: '#fff',
            width: '40%',
          },
        },
        okButtonProps: {
          type: 'default',
          style: {
            width: '40%',
          },
        },
        onCancel: () => {
          data.startHour = form.getFieldValue('startHour');
          data.endHour = form.getFieldValue('endHour');
          data.dateScheduling = moment().format('YYYY-MM-DDTHH:mm:ss');

          schedulingType(data, oldTaskData, startDate);

          form.setFieldsValue([]);
        },
        keyboard: false,
      });
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao reservar o treinamento!');
    }
  };

  const onAppointmentUpdating = async (e) => {
    try {
      const newTaskData = e.newData;
      e.cancel = true;
      const oldTaskData = JSON.parse(JSON.stringify(currentOpenTask));

      // Se for update através do botão "Confirmar"
      if (confirmationData) {
        newTaskData.confirmedBy = confirmationData.confirmedBy;
        newTaskData.idConfirmedBy = confirmationData.idConfirmedBy;
        newTaskData.confirmationDate = confirmationData.confirmationDate;
        newTaskData.stage.id = confirmationData.newStageId;
      }

      const shouldCancel = SchedulingFunctions.formatAppointmmentInfo(
        newTaskData,
        ctResources,
        taskAcceptanceDeadline,
        ctResourcesSlots
      );
      if (shouldCancel) {
        return;
      }

      if (
        newTaskData.mainInstructor &&
        newTaskData.assistantInstructor &&
        newTaskData.mainInstructor?.userId === newTaskData.assistantInstructor?.userId
      ) {
        notifySameInstructors();
        return;
      }

      // Manipula os dados dos Operadores (Instrutor, Instrutor Auxiliar, Motorista, Veículo)
      SchedulingFunctions.mapSubmitData(
        newTaskData,
        stages,
        instructorOptions,
        driverOptions,
        vehicleOptions,
        transportOptions,
        reasonNoBillingOptions,
        trainingCenterOptions,
        taskAcceptanceDeadline
      );

      let newStageId = newTaskData.stage.id;
      if (newTaskData.hasDocumentation) {
        newStageId = 5;
      }

      const newStage = stages?.find(({ id }) => id === newStageId);
      const newStageDp = JSON.parse(JSON.stringify(newStage));
      newTaskData.stage = newStageDp;

      setLoadingSchedule(true);
      await api.put('/Task/UpdateTaskScheduling', newTaskData).then(async () => {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 3000); // 3 Segundos
        });

        message.success('Treinamento atualizado!');
      });

      // Gera Atividade conforme mudança de valores dos campos
      await TaskCommentFunctions.addTaskCommentActivities(newTaskData, oldTaskData, 'Scheduling');
      await TaskCommentFunctions.addTaskCommentHistory(newTaskData, oldTaskData, 'Scheduling');
      await TaskCommentFunctions.addTaskCommentDriver(newTaskData, oldTaskData, driverOptions);
      await TaskCommentFunctions.addTaskCommentVehicle(newTaskData, oldTaskData, vehicleOptions);

      // Enviar Notificação para os Operadores (Instrutor, Instrutor Auxiliar, Motorista)
      await SchedulingFunctions.sendNotificationToOperators(newTaskData, oldTaskData);

      // Atualiza a task em tela sem precisar fazer o get denovo
      const quote = quoteList?.find(({ id }) => id === newTaskData.dealId);
      const task = quote?.tasks?.find(({ id }) => id === newTaskData.id);

      if (quote && task) {
        const indexTask = quote.tasks.indexOf(task);
        const indexQuote = quoteList.indexOf(quote);

        task.stage = newTaskData.stage;
        quote.tasks.splice(indexTask, 1, { ...task, ...newTaskData });
        quoteList.splice(indexQuote, 1, quote);
        setQuoteList([...quoteList]);
      }

      const appointment = appointments.find(({ id }) => id === newTaskData.id);
      const indexAppointment = appointments.indexOf(appointment);
      appointments.splice(indexAppointment, 1, newTaskData);
      setAppointments([...appointments]);
      setLoadingSchedule(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao atualizar o treinamento!');
    }
  };

  const onAppointmentDblClick = (e) => {
    const { appointmentData } = e;
    setCurrentTaskId(appointmentData.id);
    setIsTaskDrawerVisible(true);
  };

  const onAppointmentDeleting = async (e) => {
    e.cancel = true;
    const data = JSON.parse(JSON.stringify(e.appointmentData)); // Deep Copy da task

    // 5 = Concluido / 9 = Aguardando Conclusão / 14 = Aguardando Documentação
    if (data.stage.id === 5 || data.stage.id === 9 || data.stage.id === 14) {
      notifyDisableDelete();
      return;
    }

    onModalOk = async (stageId) => {
      setLoadingSchedule(true);
      const newStage = stages?.find(({ id }) => id === stageId);

      await api
        .delete(`/Task?taskId=${data.id}&stageId=${stageId}`)
        .then(() => {
          message.success('Treinamento Removido!');
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Algo deu errado ao tentar remover o Treinamento!');
        });

      appointments.splice(data.index, 1); // Remove da lista
      setAppointments([...appointments.map((appointment, index) => ({ ...appointment, index }))]);

      // Atualiza Overview e Resumo
      updateOverviewAndResume();

      const quote = quoteList?.find(({ id }) => id === data.dealId);
      const task = quote?.tasks?.find(({ id }) => id === data.id);

      if (quote && task) {
        const indexTask = quote.tasks.indexOf(task);
        const indexQuote = quoteList.indexOf(quote);

        task.stage = newStage;
        task.startDateFormatted = null;
        quote.tasks.splice(indexTask, 1, task);
        quoteList.splice(indexQuote, 1, quote);
        setQuoteList([...quoteList]);
      }
      setLoadingSchedule(false);
    };

    event = 'TaskRemoved';
    currentOpenTask = data;
    setReasonModalOpen(true);
  };

  const renderDataCell = (itemData) => (
    <DataCell
      itemData={itemData}
      workDays={trainingCenter?.workDays}
      blockedDates={blockedDates}
      ctResourcesSlots={ctResourcesSlots}
      appointments={appointments}
      showResourceCapacity
    />
  );

  const renderDateCell = (itemData) => (
    <DateCell itemData={itemData} workDays={trainingCenter?.workDays} blockedDates={blockedDates} />
  );

  const removeAppointmentByIndex = (index) => {
    appointments.splice(index, 1);
    setAppointments([...appointments]);
  };

  const renderAppointmentTooltip = (itemData) => (
    <AppointmentTooltipComponent
      key={itemData.id}
      itemData={itemData}
      reasonsOptions={reasonsOptions}
      schedulerRef={schedulerRef}
      setTaskRescheduling={setTaskRescheduling}
      removeAppointmentByIndex={removeAppointmentByIndex}
    />
  );

  const resourceCellRender = (itemData) => <ResourceCellRender itemData={itemData} />;

  const appointmentRender = (itemData) => <Appointment itemData={itemData} />;

  const fetchQuotes = async (dealIds) => {
    const batchSize = 30;
    const results = [];

    if (dealIds && dealIds.length > 0) {
      for (let i = 0; i < dealIds.length; i += batchSize) {
        const batch = dealIds.slice(i, i + batchSize);

        try {
          const response = await api.get(
            `/Quote?filters[0].Field=DealId&filters[0].Condition=NUMBER.IN&filters[0].Value=${batch.join(
              ','
            )}`
          );
          results.push(...response.data);
        } catch (error) {
          Utils.logError(error);
          setLoading(false);
          message.error('Oops. Algo deu errado ao buscar os Negócios!');
          throw error;
        }
      }
    }

    return results;
  };

  const fetchSlots = async () => {
    const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));
    const ctId =
      schedulingScheduleFilter?.filters?.trainingCenterId ?? scheduleData?.trainingCenterId;
    const slotsToFunction = await api
      .get(
        `/Slot?filters[0].Field=TrainingCenterId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${ctId}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao tentar buscar os Slots!');
      });

    const mappedSlots = slotsToFunction.map((slot) => ({ key: slot.id, ...slot }));
    setSlots(mappedSlots);
    return slotsToFunction;
  };

  const callSlotGeneration = (ct, readyDate, startDate, endDate, slotsToFunction) => {
    const dateKey = moment(readyDate ?? moment(scheduleDate)).format('YYYY-MM-DD');
    let filterStartDate = moment(new Date(moment(scheduleDate)).setHours(0, 0, 0, 1)).format(
      'YYYY-MM-DDTHH:mm:ss'
    );
    let filterEndDate = null;

    if (startDate && endDate) {
      filterStartDate = moment(startDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
      filterEndDate = moment(endDate.setHours(23, 59, 59, 99)).format('YYYY-MM-DDTHH:mm:ss');
    }

    // Inicio do calculo de horas de cada Slot das Pistas
    const resources = {};
    const resourcesSlots = {};
    SchedulingFunctions.calculateResourceSlots(
      resources,
      ct ?? trainingCenter,
      slotsToFunction,
      filterStartDate,
      filterEndDate
    );
    SchedulingFunctions.generateResourceSlots(resources, resourcesSlots);
    setCtResourcesSlots(resourcesSlots);
    setCtResources(resources[dateKey]);
  };

  const handleFetchTasks = async (ct, startDate, endDate) => {
    try {
      const storageDate = JSON.parse(localStorage.getItem('conecta__scheduleData'))?.date;
      const dateF = schedulingScheduleFilter?.filters?.currentDate ?? storageDate;

      let filterStartDate = null;
      let filterEndDate = null;

      if (!startDate || !endDate) {
        filterStartDate = moment(new Date(dateF).setHours(0, 0, 0, 1)).format(
          'YYYY-MM-DDTHH:mm:ss'
        );
        filterEndDate = moment(new Date(dateF).setHours(23, 59, 59, 999)).format(
          'YYYY-MM-DDTHH:mm:ss'
        );
      } else {
        filterStartDate = moment(startDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
        filterEndDate = moment(endDate.setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');
      }

      const taskFilters = {
        startDate: filterStartDate,
        endDate: filterEndDate,
        trainingCenterIds: [ct?.id ?? trainingCenter?.id],
      };

      const tasks = await fetchTasks(taskFilters);

      if (tasks?.length > 0) {
        SchedulingFunctions.getOverviewData(tasks, setOverview, filterStartDate, filterEndDate);

        SchedulingFunctions.getResumeData(tasks, setResume, filterStartDate, filterEndDate);

        if (taskRescheduling) {
          const [tasksResc] = taskRescheduling;
          const [taskResc] = tasksResc.tasks;

          setAppointments(tasks.filter((x) => x.id !== taskResc.id));
        } else {
          setAppointments(tasks);
        }
      } else {
        setAppointments([]);
        setOverview([]);
        setResume([]);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar as tarefas!');
    }
  };

  const fetchTrainingCenter = async () => {
    try {
      setLoading(true);
      const ctId = parseInt(
        schedulingScheduleFilter?.filters?.trainingCenterId ??
          JSON.parse(localStorage.getItem('conecta__scheduleData'))?.trainingCenterId,
        10
      );

      const response = await api
        .get(
          `/TrainingCenter?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Centro de Treinamento`
        )
        .then(async (res) => res.data);

      const filteredAndSortedTrainingCenters = response
        .filter(
          (tc) =>
            tc.ctQtySlots > 0 ||
            tc.inCompanyQtySlots > 0 ||
            tc.eadQtySlots > 0 ||
            tc.serviceQtySlots > 0
        )
        .map((item) => ({ ...item, label: item.commercialName, value: item.id }))
        .sort((a, b) => b.ctQtyslots - a.ctQtyslots);

      const trainingCenter = filteredAndSortedTrainingCenters?.find(({ id }) => id === ctId);
      const slotsToFunction = await fetchSlots(trainingCenter);
      const resSchedulingScheduleFilter = await fetchSchedulingScheduleFilter();
      const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));

      // Preenche os filtros
      if (resSchedulingScheduleFilter) {
        const { filters } = resSchedulingScheduleFilter;
        let ct = filteredAndSortedTrainingCenters?.find(
          ({ id }) => id === filters.trainingCenterId
        );

        if (scheduleData?.fromOverview === true) {
          const storageCtId = parseInt(scheduleData.trainingCenterId, 10);
          ct = filteredAndSortedTrainingCenters?.find(({ id }) => id === storageCtId);
          resSchedulingScheduleFilter.filters.trainingCenterId = storageCtId;

          resSchedulingScheduleFilter.filters.currentDate = moment(
            new Date(scheduleData.date)
          ).format('YYYY-MM-DD');
          scheduleDate = moment(new Date(scheduleData.date)).format('YYYY-MM-DD');
        } else {
          scheduleDate = resSchedulingScheduleFilter?.filters?.currentDate;
        }

        setTrainingCenter(ct);
        setSchedulingScheduleFilter(resSchedulingScheduleFilter);
      } else {
        setTrainingCenter(trainingCenter);
      }

      callSlotGeneration(trainingCenter, null, null, null, slotsToFunction);
      setTrainingCenterOptions(filteredAndSortedTrainingCenters);

      if (
        scheduleData?.fromOverview === true ||
        !resSchedulingScheduleFilter?.filters?.currentDate
      ) {
        localStorage.setItem(
          'conecta__scheduleData',
          JSON.stringify({ ...scheduleData, fromOverview: false })
        );

        await handleFetchTasks(trainingCenter);
      } else {
        const filterDate = moment(resSchedulingScheduleFilter?.filters?.currentDate, 'YYYY-MM-DD')
          .utc()
          .toDate();
        await handleFetchTasks(trainingCenter, filterDate, filterDate);
      }

      setLoading(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscas os dados do CT!');
    } finally {
      setLoading(false);
    }
  };

  const fetchInstructors = async () => {
    try {
      const ctId =
        schedulingScheduleFilter?.filters?.trainingCenterId ??
        JSON.parse(localStorage.getItem('conecta__scheduleData'))?.trainingCenterId;

      const filter = `/Instructor?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Instrutor&filters[1].Value=${ctId}&filters[1].Condition=NUMBER.ARRAY_CONTAIN&filters[1].Field=WorkTrainingCenters`;
      const companyInstructors = await api.get(filter).then((res) => res.data);

      const chunkSize = 30;
      let instructors = [];

      for (let i = 0; i < companyInstructors?.length; i += chunkSize) {
        const chunkInstructors = companyInstructors?.slice(i, i + chunkSize);
        const instructorIds = chunkInstructors.map(({ id }) => id).toString();
        const userFilter = `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.IN&filters[0].Value=${instructorIds}`;

        const chunkInstructorOptions = await api.get(userFilter).then((res) => res.data);
        const mappedInstructors = chunkInstructorOptions.map((item) => {
          const instructorData = companyInstructors.find(({ id }) => id === item.company.id);

          return { ...instructorData, ...item };
        });
        instructors = [...instructors, ...mappedInstructors];
      }

      setInstructorOptions(instructors);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar os Instrutores!');
    }
  };

  const fetchDrivers = async () => {
    try {
      const filter = `/Driver?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Motorista`;
      const companyDrivers = await api.get(filter).then((res) => res.data);

      const chunkSize = 30;
      let drivers = [];

      for (let i = 0; i < companyDrivers?.length; i += chunkSize) {
        const chunkDrivers = companyDrivers?.slice(i, i + chunkSize);
        const driverIds = chunkDrivers.map(({ id }) => id).toString();
        const userFilter = `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.IN&filters[0].Value=${driverIds}`;

        const chunkDriverOptions = await api.get(userFilter).then((res) => res.data);

        // Mapeamento dos motoristas para garantir campos obrigatórios
        const mappedDrivers = chunkDriverOptions.map((driver) => ({
          ...driver,
          userId: driver.id,
          userName: driver.name,
        }));

        drivers = [...drivers, ...mappedDrivers];
      }

      setDriverOptions(drivers);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar os Motoristas!');
    }
  };

  const fetchTransportCompanies = async () => {
    try {
      const response = await api.get(
        '/Transport?filters[0].Field=CompanyType&filters[0].Condition=IN&filters[0].Value=Transporte'
      );

      const responseMapped = response.data.map((tc) => ({
        ...tc,
        value: tc.id,
        label: tc.commercialName,
      }));
      setTransportOptions(responseMapped);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar as Empresas de Transporte!');
    }
  };

  const fetchVehicles = () =>
    api
      .get('/Vehicle')
      .then((res) => {
        const mappedOptions = res.data.map((item) => ({
          ...item,
          label: `${item.licensePlate} - ${item.type.name} (${item.capacity} pessoas)`,
        }));

        setVehicleOptions(mappedOptions);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar Veículos!');
      });

  const fetchTrainings = () =>
    api
      .get('/Trainings')
      .then((res) => setTrainingsOptions(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Grupos de Treinamento!');
      });

  const fetchBlockedDays = () => {
    const ctId =
      schedulingScheduleFilter?.filters?.trainingCenterId ??
      JSON.parse(localStorage.getItem('conecta__scheduleData'))?.trainingCenterId;
    return api
      .get(
        `/BlockedDay?filters[0].Field=TrainingCenterId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${ctId}`
      )
      .then((res) => setBlockedDates(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Dias Bloqueados do CT!');
      });
  };

  const fetchInstructorsBlockedDays = () =>
    api
      .get(
        `/BlockedDay?filters[0].Field=InstructorId&filters[0].Condition=ISNOTNULL&filters[0].Value=null`
      )
      .then((res) => setInstructorsBlockedDates(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Dias Bloqueados dos Instrutores!');
      });

  const fetchStages = () =>
    api
      .get(`/Stage`)
      .then((res) => setStages(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const fetchReasons = async () => {
    const reasons = await api
      .get(
        `/DenyTaskReason?filters[0].Field=Type&filters[0].Condition=IN&filters[0].Value=Scheduling,Logistics,NoBilling`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    setReasonsOptions(
      reasons?.map(({ id, name, type }) => ({
        label: name,
        value: id,
        type,
      }))
    );

    setReasonNoBillingOptions(
      reasons
        ?.filter(({ type }) => type === 'NoBilling')
        .map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
          type: item.type,
        }))
    );
  };

  const fetchTaskAcceptanceDeadline = () =>
    api
      .get(`/Settings?id=1`)
      .then(async (res) => {
        const decryptedResponse = Utils.decryptSettings(res.data);

        taskAcceptanceDeadline = decryptedResponse.taskAcceptanceDeadline;
        SchedulingFunctions.fetchBitrixProducts(
          decryptedResponse?.bitrixWebhookUrl,
          setBitrixProducts
        );
        UserFunctions.fetchBitrixUsers(decryptedResponse?.bitrixWebhookUrl, setBitrixUsers);
        setCertificateModelOptions(decryptedResponse.certificateModelList ?? []);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const fetchOpenTraining = async () => {
    if (urlParams?.action === 'OpenTraining') {
      const tasks = await api
        .get(
          `/Task?filters[0].Field=Id&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${parseInt(
            urlParams.taskId,
            10
          )}`
        )
        .then((res) => res.data);

      if (tasks?.length > 0) {
        const [task] = tasks;
        const [quote] = await fetchQuotes([task.dealId]);

        const taskMapped = { ...quote, ...task };
        setOpenTraining(taskMapped);
      }
    }
  };

  const fetchData = async () => {
    try {
      const [product] = await Promise.all([fetchProduct()]);

      setProductOptions(product);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar as opções de filtro!');
    }
  };

  const onContentReady = async (e) => {
    const { component } = e;
    const date = moment(component.option('currentDate')).format('YYYY-MM-DD');

    const currentDateChanged = date !== scheduleDate;
    scheduleDate = date;

    const toolbar = [
      {
        location: 'before',
        defaultElement: 'dateNavigator',
      },
      {
        location: 'center',
        template: `<h2 class="schedule-title">${trainingCenter?.commercialName ?? ''}</h2>`,
      },
    ];

    if (firstOpen === true && urlParams?.action === 'OpenNewSlots') {
      newSlotPopupRef.current.instance.show();
    }

    const scheduledStudents = component._filteredItems
      ?.filter(({ resource }) => resource === 'CT')
      ?.map(({ qtyStudentsPerClass }) => qtyStudentsPerClass)
      .reduce((startVal, nextVal) => startVal + nextVal, 0);
    setUsedCapacity(scheduledStudents);

    if (currentDateChanged) {
      if (!firstOpen) {
        handleFetchTasks(trainingCenter, component.getStartViewDate(), component.getEndViewDate());

        if (quoteListRef?.current) {
          quoteListRef.current.handleFilterSave();
        }
      }
      callSlotGeneration(
        trainingCenter,
        date,
        component.getStartViewDate(),
        component.getEndViewDate(),
        slots
      );
      component.option('toolbar', toolbar);
    }

    if (firstOpen && bitrixUsers.length > 0) {
      firstOpen = false;
      component.option('toolbar', toolbar);
    }

    if (urlParams?.action === 'OpenTraining') {
      // trainingOpened = true;
      component.showAppointmentPopup(openTraining);
    }

    if (currentCTName !== trainingCenter?.name) {
      currentCTName = trainingCenter?.name;
      component.option('toolbar', toolbar);
    }
  };

  const exitReschedulingMode = () => {
    const [firstTask] = taskRescheduling[0].tasks;

    appointments.push(firstTask);
    setAppointments([...appointments]);
    setTaskRescheduling(null);
  };

  useEffect(() => {
    if (urlParams?.action === 'OpenTraining') {
      // trainingOpened = true;
      schedulerRef?.current?.instance?.showAppointmentPopup(openTraining);
    }
  }, [schedulerRef]);

  useLayoutEffect(() => {
    if (urlParams?.action === 'OpenNewSlots') {
      const scheduleStorageData = {
        trainingCenterId: urlParams.ctId,
        trainingCenter: urlParams.ctName,
        date: new Date(),
        viewType: 'day',
        maxCapacityPerDay: urlParams.ctMaxCapacity,
      };

      localStorage.setItem('conecta__scheduleData', JSON.stringify(scheduleStorageData));
    }

    setScheduleParams(JSON.parse(localStorage.getItem('conecta__scheduleData')));

    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'ScheduleScheduling') {
          fields.forEach(({ name, access, isRequired, isADM }) => {
            permissions[name] = { access, isRequired, isADM };
          });
        }
      }
    );

    setFieldsPermissions(permissions);
    fetchTaskAcceptanceDeadline();
    fetchTrainingCenter();
    fetchBlockedDays();
    fetchInstructorsBlockedDays();
  }, []);

  useEffect(() => {
    fetchData();
    fetchInstructors();
    fetchDrivers();
    fetchTransportCompanies();
    fetchVehicles();
    fetchTrainings();
    fetchStages();
    fetchReasons();
    fetchOpenTraining();
  }, []);

  // Reseta o carregamento da tela quando o usuário sai da tela
  useEffect(
    () => () => {
      firstOpen = true;
      scheduleDate = moment(new Date()).format('YYYY-MM-DD');
    },
    [location]
  );

  if (loading || !ctResources || !ctResourcesSlots) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (!loading && ctResources && ctResourcesSlots) {
    return (
      <Row gutter={[12]}>
        <Col span={5}>
          {stages && trainingsOptions && trainingCenter && (
            <QuoteList
              ref={quoteListRef}
              loading={loadingSchedule}
              stages={stages}
              trainings={trainingsOptions}
              schedulerRef={schedulerRef}
              dealId={dealId}
              bitrixUsers={bitrixUsers}
              quoteList={quoteList}
              setQuoteList={setQuoteList}
              trainingCenterOptions={trainingCenterOptions}
              fetchInstructors={fetchInstructors}
              fetchSlots={fetchSlots}
              callSlotGeneration={callSlotGeneration}
              handleFetchTasks={handleFetchTasks}
              trainingCenter={trainingCenter}
              setTrainingCenter={setTrainingCenter}
              taskRescheduling={taskRescheduling}
              exitReschedulingMode={exitReschedulingMode}
              onAppointmentDblClick={onAppointmentDblClick}
              setIsTaskReadOnly={setIsTaskReadOnly}
              schedulingScheduleFilter={schedulingScheduleFilter}
              setSchedulingScheduleFilter={setSchedulingScheduleFilter}
              fieldsPermissions={fieldsPermissions}
              getCurrentDate={getCurrentDate}
              instructorBookingPopupRef={instructorBookingPopupRef}
              resumePopupRef={resumePopupRef}
              overviewPopupRef={overviewPopupRef}
              newSlotPopupRef={newSlotPopupRef}
            />
          )}
        </Col>

        {scheduleParams && (
          <Col span={19}>
            <NewSlotPopup
              newSlotPopupRef={newSlotPopupRef}
              slots={slots}
              setSlots={setSlots}
              getCurrentDate={getCurrentDate}
              trainingCenter={trainingCenter}
              bitrixProducts={bitrixProducts}
              callSlotGeneration={callSlotGeneration}
            />

            <OverviewPopup
              overviewPopupRef={overviewPopupRef}
              overviewList={overview}
              totalStudents={trainingCenter?.maxCapacityPerDay}
              getCurrentDate={getCurrentDate}
            />

            <ResumePopup
              resumePopupRef={resumePopupRef}
              resumeList={resume}
              schedulerRef={schedulerRef}
              getCurrentDate={getCurrentDate}
              ctName={trainingCenter?.name}
            />

            <InstructorBookingPopup
              instructorBookingPopupRef={instructorBookingPopupRef}
              getCurrentDate={getCurrentDate}
              instructors={instructorOptions}
              instructorsBlockedDates={instructorsBlockedDates}
            />

            <Scheduler
              ref={schedulerRef}
              disabled={loadingSchedule}
              id="scheduleScheduling"
              views={views}
              groups={groups}
              groupByDate
              dataSource={appointments}
              defaultCurrentView="Dia"
              defaultCurrentDate={schedulerDefaultDate ?? new Date(scheduleParams.date)}
              startDayHour={8}
              endDayHour={18}
              showAllDayPanel={false}
              firstDayOfWeek={1}
              maxAppointmentsPerCell={1}
              dataCellRender={renderDataCell}
              dateCellRender={renderDateCell}
              resourceCellRender={resourceCellRender}
              onAppointmentDblClick={onAppointmentDblClick}
              onAppointmentUpdating={onAppointmentUpdating}
              onAppointmentDeleting={onAppointmentDeleting}
              appointmentComponent={appointmentRender}
              appointmentTooltipRender={renderAppointmentTooltip}
              onContentReady={onContentReady}
              crossScrollingEnabled
              showCurrentTimeIndicator={false}
              editing={{
                allowResizing: false,
              }}
              onCellClick={(e) => {
                e.cancel = true;
              }}
            >
              <AppointmentDragging
                group={draggingGroupName}
                onAdd={onAppointmentAdd}
                onDragStart={(e) => {
                  if (!(fieldsPermissions?.CanMoveScheduledCards.access > 0)) {
                    e.cancel = true;
                  }
                }}
              />

              <Resource
                fieldExpr="resourceId"
                allowMultiple={false}
                dataSource={ctResources}
                label="Recurso"
              />
            </Scheduler>

            <ReasonModal
              isModalOpen={isReasonModalOpen}
              setModalOpen={setReasonModalOpen}
              reasonsOptions={reasonsOptions}
              currentOpenTask={currentOpenTask}
              event={event}
              eventSource="Scheduling"
              onOk={onModalOk}
            />

            {isTaskDrawerVisible && (
              <TaskDrawer
                taskId={currentTaskId}
                fieldsPermissions={fieldsPermissions}
                stages={stages}
                instructorOptions={instructorOptions}
                trainingsOptions={trainingsOptions}
                ctResources={ctResources}
                transportOptions={transportOptions}
                reasonNoBillingOptions={reasonNoBillingOptions}
                trainingCenterOptions={trainingCenterOptions}
                vehicleOptions={vehicleOptions}
                driverOptions={driverOptions}
                productOptions={productOptions}
                certificateModelOptions={certificateModelOptions}
                setIsTaskDrawerVisible={setIsTaskDrawerVisible}
                isReasonModalOpen={isReasonModalOpen}
                setReasonModalOpen={setReasonModalOpen}
                reasonsOptions={reasonsOptions}
                event={event}
                onModalOk={onModalOk}
                taskAcceptanceDeadline={taskAcceptanceDeadline}
                ctResourcesSlots={ctResourcesSlots}
                setAppointments={setAppointments}
                setQuoteList={setQuoteList}
                appointments={appointments}
                quoteList={quoteList}
                isTaskReadOnly={isTaskReadOnly}
                setIsTaskReadOnly={setIsTaskReadOnly}
              />
            )}
          </Col>
        )}
      </Row>
    );
  }
}

const ScheduleScheduling = memo(ScheduleSchedulingComponent);
export default ScheduleScheduling;
