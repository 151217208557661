/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Form, Modal, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import { uploadFiles } from '../../Services/firebaseService';

import TaskCommentFunctions from './TaskCommentFunctions';

export default function ModalUploadFile({
  setIsOpen,
  loading,
  currentOpenTask,
  handleTaskUpdate,
  type,
  formTaskDrawer,
}) {
  const [form] = Form.useForm();
  const [loadingModal, setLoadingModal] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleChange = (info) => {
    const newFileList = info.fileList.map((fileProps) => ({ ...fileProps, status: 'done' }));
    setFileList(newFileList);
  };

  const handleImport = async (file) => {
    if (file.status !== 'removed') {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
    }
  };

  const submit = async () => {
    if (fileList.length > 0) {
      setLoadingModal(true);

      if (type === 'finalAttendanceDocList') {
        currentOpenTask.stage.id = 14; // 14 = Aguardando Documentação
        formTaskDrawer.setFieldValue('stage', { id: 14 });
      }

      const uploadedFiles = [];
      for (const file of fileList) {
        const fileExtension = file.name.split('.').pop();
        const fileName = `${currentOpenTask.id}_${type}_${Date.now()}.${fileExtension}`;
        const res = await uploadFiles(
          file.originFileObj,
          'trainings',
          currentOpenTask.id,
          fileName
        );

        uploadedFiles.push({
          label: file.name,
          name: fileName,
          url: res,
        });
      }

      if (!currentOpenTask[type]) {
        currentOpenTask[type] = [];
      }
      const updatedData = { ...currentOpenTask, [type]: uploadedFiles };
      formTaskDrawer.setFieldValue(type, [...currentOpenTask[type], ...uploadedFiles]);

      let eventName = '';
      switch (type) {
        case 'nfTransportation':
          eventName = 'NFTransportationUploaded';
          break;
        case 'nfTraining':
          eventName = 'NFTrainingUploaded';
          break;
        case 'initialAttendanceDocList':
          eventName = 'InitialAttendanceListUploaded';
          break;
        case 'finalAttendanceDocList':
          eventName = 'FinalAttendanceListUploaded';
          break;
        case 'instructorForm':
          eventName = 'InstructorFormUploaded';
          break;
        default:
          break;
      }

      await TaskCommentFunctions.addTaskCommentUploadFile(updatedData, eventName, 'Scheduling');
      await handleTaskUpdate('save');

      setFileList([]);
      setLoadingModal(false);
      setIsOpen(false);
    }
  };

  const closeModal = () => {
    setFileList([]);
    setIsOpen(false);
  };

  return (
    <Modal
      className="modal-Training-Complet"
      title="Enviar Arquivo"
      maskClosable={false}
      closable={!loading && !loadingModal}
      open={true}
      cancelText="Fechar"
      onCancel={closeModal}
      destroyOnClose
      footer={[
        <Button onClick={closeModal} disabled={loading || loadingModal}>
          Fechar
        </Button>,
        <Button
          form="modalUploadFile"
          type="primary"
          key="submit"
          htmlType="submit"
          loading={loading || loadingModal}
        >
          Enviar
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="modalUploadFile"
        layout="vertical"
        autoComplete="off"
        onFinish={submit}
        preserve={false}
      >
        <Form.Item
          name="attendanceList"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Upload
            multiple
            beforeUpload={() => {
              setFileList([]);
            }}
            onChange={handleChange}
            customRequest={({ file }) => handleImport(file)}
            disabled={loading}
            fileList={fileList}
          >
            <Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}
